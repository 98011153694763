body {
  margin: 0;
  min-height: 100vh;
  background-color: #101010;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100vh;
}

.icon-scale {
  transition: transform 0.3s ease;
}

.icon-scale:hover {
  transform: scale(1.2);
}

.avatar-blur {
  filter: brightness(0.5); /* Adjust the blur intensity as needed */
  transition: 0.2s;
}

.test-idiot {
  height: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd !important;
  border-radius: 5px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999 !important;
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777 !important;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.newBadge {
  display: unset !important;
}